import React, { useEffect, useState } from 'react';
import './App.css';
import underConstruction from './under-construction.jpg';

const URL = 'https://scythedigitalcorp.wixsite.com/website'

function App() {
  const [count, setCount] = useState(3)

  useEffect(() => {
    const myInterval = setInterval(myTimer, 1000);

    function myTimer() {
      if (count === 0) window.open(URL, '_self', 'noreferrer,noopener')
      setCount(count === 0 ? 0 : count - 1)
    }

    function myStopFunction() {
      clearInterval(myInterval);
    }

    return myStopFunction
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count])

  return (
    <div className="App">
      <header className="App-header">
        <img src={underConstruction} className="App-logo" alt="logo" />
        <code>{`<dev>`}Thank you for visiting Scythe Corp's website.
          We are currently developing our website. Watch this space for updates.{`</dev>`}
        </code>
        <code>
          You will automatically be redirected to our current&nbsp;
          <a href={URL} target='_self' rel="noreferrer noopener">website</a>&nbsp;
          in {count} seconds ...
        </code>
      </header>
    </div>
  );
}

export default App;
